import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoVisitaService {

  private API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public getTabelaTipoVisita(){
    return this.http.get(`${this.API}TabelaPesquisa/TipoVisita`);
  }

  public fazPesquisaTipoVisita(filtro, componente, campo, condicao?){

    let httpParams

    if(condicao){
      httpParams = new HttpParams({
        fromObject: {
          tabela: componente,
          coluna: campo,
          valor: filtro,
          condicaoPadrao: condicao
        }
      })
    } else {
      httpParams = new HttpParams({
        fromObject: {
          tabela: componente,
          coluna: campo,
          valor: filtro
        }
      })
    }

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})

  }

  public post(dados: object): Promise<any> {
    return this.http.post(`${this.API}TipoVisita`, dados).toPromise()
  }

  public delete(id: number): Promise<any> {
    return this.http.delete(`${this.API}TipoVisita/${id}`).toPromise()
  }

  public getAll(): Promise<any[]>{
    return this.http.get<any[]>(`${this.API}TipoVisita`).toPromise()
  }
}
