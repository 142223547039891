import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoServicoService {

  API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public getTabelaTipoServico() {
    return this.http.get(`${this.API}TabelaPesquisa/VWTipoServico`);
  }
  
  public getTodosTipoServico(){
    return this.http.get(`${this.API}TipoServico/GetTodosTipoServico`)
  }

  public fazPesquisaTipoServico(filtro, componente, campo) {
    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })
    
    return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
  }


  public TipoServicoIndividual(id: number): Promise<any> {
    return this.http.get(`${this.API}TipoServicoCliente/GetTipoServico/${id}`).toPromise()
  }

  public novoTipoServico(dados: object): Promise<any> {
    return this.http.post(`${this.API}TipoServico`, dados).toPromise()
  }

  public deletarTipoServicoIndividual(id: number): Promise<any> {
    return this.http.delete(`${this.API}TipoServico/${id}`).toPromise()
  }

}
