import { Injectable, EventEmitter } from '@angular/core';
import { concatMap, distinct, filter, map, reduce, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { PerfilAcessoService } from './perfil-acesso.service';
import { PerfilAcessoDTO } from '../models/perfilAcessoDTO';
import { UsuarioService } from './usuario.service';
import { Usuario } from '../models/usuario';
import { ClienteService } from './cliente.service';
import { EventManager } from '@angular/platform-browser';
import { Cliente } from '../models/cliente';
import { EmpresaService } from './empresa.service';
import { NumSolicitacaoService } from './num-solicitacao.service';
import { ResponsavelService } from './responsavel.service';
import { NumAtendimnetoService } from './num-atendimneto.service';
import { AtendimentoService } from './atendimento.service';
import { TipoServicoService } from './tipo-servico.service';
import { UnidadeService } from './unidade.service';
import { EquipeService } from './equipe.service';
import { ClienteProspectsService } from './cliente-prospects.service';
import { FormValidations } from './form-validations';
import { FeriadoService } from './feriado.service';
import { AtaReuniaoService } from './ata-reuniao.service';
import { DatePipe } from '@angular/common';
import { AgendaService } from './agenda.service';
import { BriefingService } from './briefing.service';
import { TipoVisitaService } from './tipo-visita.service';
import { FechamentoService } from './fechamento.service';

@Injectable({
  providedIn: 'root'
})
export class PesquisaService {

  emitirPerfilAcessoAchado = new EventEmitter<PerfilAcessoDTO>()
  emitirUsuarioAchado = new EventEmitter<Usuario>()
  emitirClienteAchado = new EventEmitter<any>()
  emitirEmpresaAchado = new EventEmitter<any>()
  emitirNumAtendimento = new EventEmitter<any>()
  emitirNumSolicitacao = new EventEmitter<any>()
  emitirResponsavel = new EventEmitter<any>()

  dados: any

  constructor(private datePipe: DatePipe,
    private perfilAcessoService: PerfilAcessoService,
    private usuarioService: UsuarioService,
    private clienteService: ClienteService,
    private empresaService: EmpresaService,
    private numSolicitacao: NumSolicitacaoService,
    private numAtendimento: NumAtendimnetoService,
    private responsavel: ResponsavelService,
    private atendimentoService: AtendimentoService,
    private tipoServico: TipoServicoService,
    private unidade: UnidadeService,
    private equipe: EquipeService,
    private clienteProspects: ClienteProspectsService,
    private feriado: FeriadoService,
    private ataReuniao: AtaReuniaoService,
    private agenda: AgendaService,
    private briefing: BriefingService,
    private tipoVisitaService: TipoVisitaService,
    private fechamentoService: FechamentoService
  ) { }

  getCombobox(componente, token) {

    switch (componente) {

      case "PerfilAcesso":
        return this.perfilAcessoService.getTabelaPerfilAcesso(token)
      case "Usuario":
        return this.usuarioService.getTabelaUsuario(token)
      case "Cliente":
        return this.clienteService.getTabelaCliente()
      case "EmpresaCliente":
        return this.empresaService.getTabelaEmpresa()
      case "VWSolicitacao_TabPesquisa":
        return this.numSolicitacao.getTabelaNumSolicitacao()
      case "VWAtendimento_TabPesquisa":
        return this.numAtendimento.getTabelaNumAtendimento()
      case "VWColaboradorEquipe":
        return this.usuarioService.getTabelaUsuario(token)
      case "Atendimento":
        return this.atendimentoService.getTabelaAtendimento()
      case "VWTipoServico":
        return this.tipoServico.getTabelaTipoServico()
      case "VWEmpresaColaboradorResponsavelLegal":
        return this.empresaService.pesquisaEmpresaCliente()
      case "Filial":
        return this.unidade.getTabelaUnidade().pipe(
          map((valor: any) => {
            valor[0].ListaCampos = valor[0].ListaCampos.filter(item => item.Campo != 'Id')
            return valor
          }),
        )
      case "VWEquipe":
        return this.equipe.getTabelaEquipe().pipe(
          map((valor: any) => {
            valor[0].ListaCampos = valor[0].ListaCampos.filter(item => item.Campo != 'Id')
            return valor
          }),
        )
      case "VWContatoClienteProspect":
        return this.clienteProspects.getClienteProspects()
      case "VWFeriado":
        return this.feriado.getTabelaFeriado().pipe(
          map((valor: any) => {
            valor[0].ListaCampos = valor[0].ListaCampos.filter(item => item.Campo != 'Id')
            return valor
          }),
        )
      case "VWAtaReuniao":
        return this.ataReuniao.getTabelaAtaReuniao().pipe(
          map((valor: any) => {
            valor[0].ListaCampos = valor[0].ListaCampos.filter(item => item.Campo != 'Id')
            return valor
          }),
        )
      case "VWEvento":
        return this.agenda.getTabelaEventos().pipe(
          map((valor: any) => {
            valor[0].ListaCampos = valor[0].ListaCampos.filter(item => item.Campo != 'Id')
            return valor
          }),
        )
      case "VWBriefing":
        return this.briefing.getTabelaBriefing().pipe(
          map((valor: any) => {
            valor[0].ListaCampos = valor[0].ListaCampos.filter(item => item.Campo != 'Id')
            return valor
          }),
        )
      case "TipoVisita":
        return this.tipoVisitaService.getTabelaTipoVisita()
      case "VWFechamentoNegocio":
        return this.fechamentoService.getTabelaFechamento()

    }
  }

  // FAZ A PESQUISA COM OS VALORES DE PESQUISA OBTIDOS
  getPesquisar(campo, filtro, componente, token?, condicao?, condicaoQuery?) {

    switch (componente) {
      case "PerfilAcesso":
        return this.perfilAcessoService.fazPesquisaPerfilAcesso(token, componente, filtro, campo)
      case "Usuario":

        if (condicao.mostrarBloqueados) {
          return this.usuarioService.fazPesquisaUsuario(token, filtro, componente, campo).pipe(
            map(results => results.filter(r => r.Bloqueado === 0)
            )
          )
        } else {
          return this.usuarioService.fazPesquisaUsuario(token, filtro, componente, campo)
        }
      case "Cliente":
        if (condicao.naoMostrarInativos) {
          return this.clienteService.fazPesquisaCliente(filtro, componente, campo, condicaoQuery)
        } else {
          return this.clienteService.fazPesquisaCliente(filtro, componente, campo, condicaoQuery)
        }
      case "EmpresaCliente":
        
        if(condicaoQuery){
        return this.empresaService.fazPesquisaEmpresa(filtro, componente, campo, condicaoQuery)
          .pipe(
            map(
              contatos => {

                for (let i = 0; i < contatos.length; i++) {
                  contatos[i].CNPJ = FormValidations.formatarCampo(contatos[i].CNPJ, "cnpj")
                }

                return contatos

              }
            )
          )
        }
        else{
          return this.empresaService.fazPesquisaEmpresa(filtro, componente, campo)
          .pipe(
            map(
              contatos => {

                for (let i = 0; i < contatos.length; i++) {
                  contatos[i].CNPJ = FormValidations.formatarCampo(contatos[i].CNPJ, "cnpj")
                }

                return contatos

              }
            )
          )
        }
      case "VWSolicitacao_TabPesquisa":
        return this.numSolicitacao.fazPesquisaNumSolicitacao(filtro, componente, campo)
      case "VWAtendimento_TabPesquisa":
        return this.numAtendimento.fazPesquisaNumAtendimento(filtro, componente, campo)

      case "Atendimento":
        return this.atendimentoService.fazPesquisaAtendimento(filtro, componente, campo).pipe(

          map(
            results => {

              for (let i = 0; i < results.length; i++) {

                if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,3}$/.test(results[i]['Cadastro'])) {
                  results[i]['Cadastro'] = FormValidations.formatarCampo(FormValidations.retirarFormatacaoDataHora(results[i]['Cadastro'].substr(0, 19)), 'dataAMDtoDMA')
                } else {
                  results[i]['Cadastro'] = FormValidations.formatarCampo(FormValidations.retirarFormatacaoDataHora(results[i]['Cadastro']), 'dataAMDtoDMA')
                }

              }

              return results

            })

        )
      case "VWColaboradorEquipe":
        return this.usuarioService.fazPesquisaUsuarioEquipe(filtro, componente, campo, condicao.idDepartamento)
      case "VWFeriado":
        return this.feriado.fazPesquisaFeriado(filtro, componente, campo).pipe(
          //Função para formatação de data da pesquisa feriados
          map(
            (results: any) => {
              for (let i = 0; i < results.length; i++) {
                results[i]['Data'] = FormValidations.formatarCampo(FormValidations.retirarFormatacaoDataHora(results[i]['Data']), 'dataAMDtoDMA')
              }
              return results
            }
          )

        )
      case "VWEmpresaColaboradorResponsavelLegal":

        return this.empresaService.fazPesquisaEmpresa(filtro, componente, campo, condicaoQuery)

      case "VWTipoServico":
        return this.tipoServico.fazPesquisaTipoServico(filtro, componente, campo)
      case "Filial":
        return this.unidade.fazPesquisaUnidade(filtro, componente, campo)
      case "VWEquipe":
        return this.equipe.fazPesquisaEquipe(filtro, componente, campo)
      case "VWContatoClienteProspect":

        if (campo == "ContatoCliente") {
          return this.clienteProspects.PesquisaClienteProspects(filtro, componente, campo, condicaoQuery)
        }

        else {
          return this.clienteProspects.PesquisaClienteProspects(filtro, componente, campo, condicaoQuery)
            .pipe(
              concatMap(from),
              map(c => ({
                Id: c.Id,
                Prospect: c.Prospect,
                ContatoCliente: c.ContatoCliente,
                Email: c.Email,
                Telefone: c.Telefone
              })),
              distinct((c: any) => c.Prospect),
              reduce((clientes, cliente) => [...clientes, cliente], [])
            )
        }
      case "VWAtaReuniao":

        // METODO PARA FORMATAR DATAS A SEREM MOSTRADAS PARA USUARIO
        return this.ataReuniao.fazPesquisaAtaReuniao(filtro, componente, campo).pipe(
          map((Atas: any) => {

            Atas.forEach(Ata => {
              Ata.Data = this.datePipe.transform(Ata.Data, 'dd-MM-yyyy')
            })
            return Atas
          })
        )

      case "VWEvento":
        return this.agenda.fazPesquisaEventos(filtro, componente, campo).pipe(
          map((Eventos: any) => {

            Eventos.forEach(Evento => {
              Evento.Data = this.datePipe.transform(Evento.Data, 'dd-MM-yyyy')
            })
            return Eventos
          })
        )

      case "VWBriefing":
        return this.briefing.fazPesquisaBriefing(filtro, componente, campo).pipe(

          map((Briefings: any) => {

            Briefings.forEach(briefing => {

              briefing.Data = this.datePipe.transform(briefing.Data, 'dd-MM-yyyy')
            })
            return Briefings
          })
        )
      case "TipoVisita":
        return this.tipoVisitaService.fazPesquisaTipoVisita(filtro, componente, campo)
      case "VWFechamentoNegocio":
        return this.fechamentoService.fazPesquisaFechamento(filtro, componente, campo)

    }
  }

  // INFORMA O CAMPO DA LISTA QUE SERÁ EXIBIDO NO SELECT
  getCampoDaLista(componente) {

    switch (componente) {
      case "PerfilAcesso":
        return ["DSPerfil"]
      case "Usuario":
        return ["Nome"]
      case "VWColaboradorEquipe":
        return ["Nome"]
      case "Cliente":
        return ["Nome"]
      case "EmpresaCliente":
        return ["RazaoSocial", "CNPJ"]
      case "VWSolicitacao_TabPesquisa":
        return ["Id"]
      case "VWAtendimento_TabPesquisa":
        return ["Id"]
      case "Atendimento":
        return ['Id', 'Cadastro', 'Cliente', 'Empresa']
      case "VWTipoServico":
        return ["Servico"]
      case "Filial":
        return ["NomeFilial"]
      case "VWEquipe":
        return ['Equipe']
      case "VWContatoClienteProspect":
        return ['Prospect']
      case "VWFeriado":
        return ["Descricao", "Data", "Unidade"]
      case "VWAtaReuniao":
        return ["Titulo", "Cliente", "Data"]
      case "VWEvento":
        return ["Titulo", "Tipo", "Data"]
      case "VWBriefing":
        return ["Id", "ResponsavelNegociacao", "Data", "Nome"]
      case "TipoVisita":
        return ["Descricao"]
      case "VWFechamentoNegocio":
        return ["Id", "Cliente", "Produto"]
      case "VWEmpresaColaboradorResponsavelLegal":
        return ["RazaoSocial", "CNPJ"]
    }
  }


  // RECEBE O PERFIL ACHADO PELA PESQUISA E ENVIA PARA A ATELA DE PERFIS DE ACESSO (NÃO UTILIZADO EM NOVOS COMPONENTES, AO INVÉS DE UTILIZAR ISSO SE UTILIZA O AFTERCLOSE DO COMPONENTE DIALOG)
  recebeItemAchado(obj, componente) {

    switch (componente) {
      case 'PerfilAcesso':
        this.emitirPerfilAcessoAchado.emit(obj)
        break;
      case 'Usuario':
        this.emitirUsuarioAchado.emit(obj)
        break;
      case 'Cliente':
        this.emitirClienteAchado.emit(obj)
        break;
      case 'EmpresaCliente':
        this.emitirEmpresaAchado.emit(obj)
        break;
      case 'VWAtendimento_TabPesquisa':
        this.emitirNumAtendimento.emit(obj)
        break;
      case 'VWSolicitacao_TabPesquisa':
        this.emitirNumSolicitacao.emit(obj)
        break;
    }
  }





}
