import { Usuario } from "./../../../../models/usuario";
import { Arquivo } from "./../solucao-tarefa/solucao-tarefa.component";
import { LoginService } from "./../../../../services/login.service";
import { UsuarioService } from "./../../../../services/usuario.service";
import { AtendimentoService } from "./../../../../services/atendimento.service";
import { id } from "@swimlane/ngx-charts";
import { AtendimentoSolicitacaoService } from "./../../../../services/atendimento-solicitacao.service";
import { TipoServicoService } from "./../../../../services/tipo-servico.service";
import { ClienteService } from "./../../../../services/cliente.service";
import { Cliente } from "./../../../../models/cliente";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Input,
  Inject,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PesquisaComponent } from "../pesquisa/pesquisa.component";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { ignoreElements, map, startWith } from "rxjs/operators";
import * as _ from "lodash";
import { PaginaErroComponent } from "../pagina-erro/pagina-erro.component";
import { getLocaleDateTimeFormat } from "@angular/common";
import { EquipeService } from "../../../../services/equipe.service";

interface Atendimento {
  Id: number;
  idCliente: number;
  ContatoCliente: String;
  ListaSolicitacao: {
    Id: number;
    idAtendimento: number;
    idTipoServico: number;
    idEmpresa: number;
    Solicitacao: String;
    Prioridade: String;
  };
}

@Component({
  selector: "ngx-solicitacao",
  templateUrl: "./solicitacao.component.html",
  styleUrls: ["./solicitacao.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class SolicitacaoComponent implements OnInit {
  //Bloco responsável pelo upload de anexo
  private DadosRecebidos: any;
  public ListaArquivosUpload: any[] = [];
  public contentType: string = "";
  public colunaNomeArquivo: string[] = ["nome"];

  public OrigemAtendimento: string[] = ['Presencial','Telefone', 'E-mail', 'Whatsapp', 'Facebook' , 'Instagram' , 'Outras mídias sociais' , 'Tratativa Interna Focus']


  Equipes;
  EquipesParticipantes;
  empresas = [];
  SelectEmpresas: Array<any> = [];
  SelectDepartamentos;
  SelectFiliais;
  FilialSelecionada;
  SelectPrioridade;
  SelectServicos;
  SelectPrioridades = ["Urgente", "Alta", "Normal", "Baixo"];
  SelectContato;
  TipoServico;
  usuarioRelator;
  Usuario;
  hoje;
  VerificaDepartamento;

  CheckboxStatus;
  CheckboxAtribuir;
  CheckboxDisable: boolean = true;
  Solicitacao: Atendimento;

  public escreverContato = false;

  @ViewChild("filial") filialSelect;

  larguraPagina;

  public Empresas;
  public formulario: FormGroup;

  public FormularioAtendimento: FormGroup;
  public FormularioSolicitacao: FormGroup;

  public aparecerSpinner = false;

  public Naturezas = ["Solicitação", "Reclamação", "Elogio","FeedBack"];

  // Váriaveis responsáveis pelo material Chips dos participantes

  @ViewChild("UsuarioInput") UsuarioInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public ClientesObjetoLista = [];
  public listaParticipantesEvento = [];
  public ClientesParticipantesLista = [];
  public ClientesLista: string[] = [];
  public ClientesFiltrados: Observable<string[]>;
  public ClientesSelecionados: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public clienteService: ClienteService,
    public tipoServicoService: TipoServicoService,
    public atendimentoSolicitacaoService: AtendimentoSolicitacaoService,
    public atendimentoService: AtendimentoService,
    public loginService: LoginService,
    public equipeService: EquipeService,
    private dialogRef: MatDialogRef<SolicitacaoComponent>
  ) {
    this.Usuario = data.usuarioLogado;

    this.larguraPagina = document.documentElement.clientWidth;
  }

  async ngOnInit() {
    await this.montaFormulario();
    await this.RetornaEquipesParticipantes();
    await this.insereDados();
    await this.orderSelects();
    await this.montaSelectFilial();
    await this.buscaUsuarioRelator();
    await this.formataData;
    this.formulario.valueChanges.subscribe((x) => {
      if (x.Empresa != "" && x.DepartamentoDestino != "" && x.Filial == "") {
        this.selecionaFilial();
      }
    });
  }

  public orderSelects() {
    this.SelectDepartamentos = _.orderBy(
      this.SelectDepartamentos,
      ["Nome"],
      ["asc"]
    );
    this.SelectServicos = _.orderBy(this.SelectServicos, ["Titulo"], ["asc"]);
    this.SelectFiliais = _.orderBy(this.SelectFiliais, ["viewValue"], ["asc"]);
  }

  public montaFormulario() {
    this.formulario = this.formBuilder.group({
      Id: 0,
      idCliente: null,
      Cliente: ["", Validators.required],
      Empresa: ["", Validators.required],
      DepartamentoDestino: ["", Validators.required],
      Filial: ["", Validators.required],
      TipoServico: ["", Validators.required],
      Prioridade: ["", Validators.required],
      Contato: ["", Validators.required],
      Solicitacao: ["", [Validators.required]],
      OrigemAtendimento: ["", Validators.required],
      Atribuir: false,
      StatusAtendido: false,
      Checkbox: false,
      NaturezaAtendimento: [null, Validators.required],
    });

    this.FormularioSolicitacao = this.formBuilder.group({
      Id: 0,
      idAtendimento: 0,
      Solicitacao: "",
      PrazoExterno: "0000-00-00T00:00:00",
      Prioridade: "",
      NaturezaAtendimento: "",
      idTipoServico: 0,
      Departamento: "",
      idDepartamento: 0,
      TipoServico: 0,
      idEmpresa: 0,
      Empresa: "",
      idTarefa: 0,
      idUsuarioAtribuido: 0,
      UsuarioAtribuido: "",
      Status: "",
      idFilialFocus: 0,
      FilialFocus: "",
      ListaHistorico: [],
      ListaAnexo: [],
      ListaSolicitacaoDependente: [],
      idUsuarioRelator: 0,
      UsuarioRelator: "",
      StatusAtendido: false,
      RetornoCliente: "",
    });

    this.FormularioAtendimento = this.formBuilder.group({
      Id: 0,
      StatusAtendimento: "",
      PrazoExterno: "0000-00-00T00:00:00",
      idCliente: 0,
      Cliente: "",
      ContatoCliente: "",
      CanalSolicitacao: "",
      DataCadastro: "",
      UsuarioCadastro: "",
      DataAlteracao: "",
      UsuarioAlteracao: "",
      ListaSolicitacao: [],
    });
  }

  public limparFormulario() {
    this.formulario.patchValue({
      idCliente: null,
      Cliente: "",
      idEmpresa: null,
      Empresa: "",
      idDepartamentoDestino: null,
      DepartamentoDestino: "",
      Filial: "",
      idTipoServico: null,
      TipoServico: "",
      Prioridade: "",
      Contato: "",
      Solicitacao: "",
      Atribuir: null,
      StatusAtendido: 0,
    });
  }

  public BuscarCliente() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      componente: "Cliente",
      condicaoQuery: `Cliente.status <> 'Inativo'`
    };

    this.dialog
      .open(PesquisaComponent, dialogConfig)
      .afterClosed()
      .subscribe((cliente) => {
        this.formulario.patchValue({
          idCliente: cliente.Id,
          Cliente: cliente.Nome,
        });
        this.montaSelectEmpresasContato(cliente.Id);
      });
  }

  public montaSelectEmpresasContato(idCliente) {
    this.clienteService.getCliente(idCliente).then((response: any) => {

      response[0].ListaEmpresas.forEach((e) => {
        if (e.Status != "Inativo") {
          this.SelectEmpresas.push(e);
        }
      });

      this.SelectContato = response[0].ListaContatos;
    });
  }
  public excluirCliente() {
    this.SelectEmpresas = [];
    this.SelectContato = [];
    this.formulario.patchValue({
      idCliente: 0,
      Cliente: null,
      Contato: "",
    });
  }
  formataData() {
    this.hoje = new Date();
  }

  async RetornaEquipesParticipantes() {
    await this.equipeService
      .getAll()
      .toPromise()
      .then((response: any) => (this.Equipes = response));

    this.EquipesParticipantes = this.Equipes.filter((x) =>
      x.ListaColaborador.map((j) => j.idUsuario).includes(this.Usuario.Id)
    );
  }

  buscaUsuarioRelator() {
    this.usuarioRelator = this.loginService.getUsuarioDadosCompletos();
  }

  public MudaSelects() {
    this.formulario.patchValue({
      Filial: "",
    });

    let ids = this.formulario.value.DepartamentoDestino.Id;

    this.MontarSelectTipoServico(ids);
    this.CheckboxStatusMomento();
  }

  public MontarSelectTipoServico(ids) {
    this.SelectServicos = [];
    this.SelectDepartamentos.forEach((Departamento: any) => {
      this.SelectServicos.push({
        Titulo: Departamento.Nome,
        Id: Departamento.Id,
        TipoServico: this.TipoServico.filter(
          (TipoServico: any) => TipoServico.Departamento === Departamento.Nome
        ),
      });
    });
    this.SelectServicos = this.SelectServicos.filter(
      (select: any) => select.TipoServico.length > 0
    );
    if (ids) {
      this.SelectServicos = this.SelectServicos.filter(
        (select: any) => ids == select.Id
      );
    }
  }

  insereDados() {
    this.tipoServicoService
      .fazPesquisaTipoServico("", "VWTipoServico", "Servico")
      .toPromise()
      .then((lista: any) => {
        this.TipoServico = lista;
      });

    this.atendimentoSolicitacaoService.getDepartamentos().then((lista) => {
      this.SelectDepartamentos = lista;
    });
  }

  public montaSelectFilial() {
    this.clienteService.getFiliais().then((response) => {
      this.montarFiliaisSelect(response);
    });
  }

  public montarFiliaisSelect(filiais) {
    filiais.forEach((filial) => {
      this.SelectFiliais.push({
        value: filial.NomeFilial,
        viewValue: `${filial.UF}-${filial.NomeFilial}`,
        Id: filial.Id,
      });
    });
  }
  public selecionaFilial() {
    this.atendimentoSolicitacaoService
      .getFilial(
        this.formulario.value.DepartamentoDestino.Id,
        this.formulario.value.Empresa.Id
      )
      .toPromise()
      .then((response: any) => {
        this.formulario.patchValue({
          Filial: this.SelectFiliais.filter((x) => x.Id == response.Id)[0],
          Checkbox: response.Departamento,
        });
      });
  }

  public verificaDepartamentoUsuario() {
    this.VerificaDepartamento = this.EquipesParticipantes.map(
      (c) => c.idDepartamento
    ).includes(this.formulario.value.DepartamentoDestino.Id);
  }

  public async CheckboxStatusMomento() {
    this.verificaDepartamentoUsuario();
    if (this.VerificaDepartamento == true) {
      this.CheckboxDisable = false;
    }
    if (this.VerificaDepartamento == false) {
      this.CheckboxDisable = true;
      this.formulario.patchValue({
        Atribuir: false,
        StatusAtendido: false,
      });
    }
  }

  inserirContato() {
    this.escreverContato = !this.escreverContato;

    this.formulario.patchValue({
      Contato: null,
    });
  }

  setCheckbox() {
    if (this.formulario.value.StatusAtendido == true) {
      this.formulario.value.Atribuir = true;
    }
  }

  public StatusCheckbox() {
    if (this.formulario.value.StatusAtendido == true) {
      this.FormularioSolicitacao.value.StatusAtendido = 1;
      this.formulario.value.Atribuir = true;
    } else {
      this.FormularioSolicitacao.value.StatusAtendido = 0;
    }
  }

  public AtribuirCheckBox() {
    if (this.formulario.value.Atribuir == true) {
      this.FormularioSolicitacao.value.idUsuarioAtribuido =
        this.usuarioRelator.Id;

      this.FormularioSolicitacao.value.UsuarioAtribuido =
        this.usuarioRelator.Nome;
    }
  }

  //bloco relacionado aos arquivos de anexo

  AbrirCaixaArquivos() {
    document.getElementById("CaixaArquivos").click();
  }

  async ArquivosSelecionados(arquivos: any) {
    await this.CriarObjetoUpload(arquivos);
  }

  async CriarObjetoUpload(listaAnexo: FileList) {
    for (let i = 0; i < listaAnexo.length; i++) {
      let lerArquivo: FileReader = new FileReader();
      lerArquivo.onload = (x) => {
        this.contentType = "";
        if (
          listaAnexo[i].name.substring(
            listaAnexo[i].name.length - 3,
            listaAnexo[i].name.length
          ) == "pdf" ||
          "PDF"
        )
          this.contentType = "application/pdf";
        if (
          listaAnexo[i].name.substring(
            listaAnexo[i].name.length - 3,
            listaAnexo[i].name.length
          ) == "xls"
        )
          this.contentType = "application/xls";
        if (
          listaAnexo[i].name.substring(
            listaAnexo[i].name.length - 4,
            listaAnexo[i].name.length
          ) == "xlsx"
        )
          this.contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (
          listaAnexo[i].name.substring(
            listaAnexo[i].name.length - 3,
            listaAnexo[i].name.length
          ) == "txt"
        )
          this.contentType = "text/plain";
        if (
          listaAnexo[i].name.substring(
            listaAnexo[i].name.length - 3,
            listaAnexo[i].name.length
          ) == "csv"
        )
          this.contentType = "application/octet-stream";
        if (
          listaAnexo[i].name.substring(
            listaAnexo[i].name.length - 4,
            listaAnexo[i].name.length
          ) == "docx"
        )
          this.contentType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        if (this.contentType != "") {
          if (this.DadosRecebidos != undefined) {
            this.ListaArquivosUpload.push({
              idTarefaSolicitacao: this.DadosRecebidos["idTarefa"] || 0,
              idUsuario: this.usuarioRelator.Id,
              NomeArquivo: listaAnexo[i].name,
              DataInclusao: new Date(Date.now()).toISOString().split(".")[0],
              contentType: this.contentType,
              Base64String: btoa(lerArquivo.result.toString()),
            });
          } else {
            this.ListaArquivosUpload.push({
              idTarefaSolicitacao: 0,
              idUsuario: this.usuarioRelator.Id,
              NomeArquivo: listaAnexo[i].name,
              DataInclusao: new Date(Date.now()).toISOString().split(".")[0],
              contentType: this.contentType,
              Base64String: btoa(lerArquivo.result.toString()),
            });
          }
        } else {
          this.abrirPaginaErro("O formato do arquivo não é aceito!", "interno");
        }
        this.ListaArquivosUpload = [...this.ListaArquivosUpload];
      };
      lerArquivo.readAsBinaryString(listaAnexo[i]);
    }
  }

  ExcluirArquivo(i) {
    this.ListaArquivosUpload.splice(i, 1);
    this.ListaArquivosUpload = [...this.ListaArquivosUpload];
  }

  public montaObjGravar() {
    this.FormularioSolicitacao.patchValue({
      Id: 0,
      idAtendimento: 0,
      PrazoExterno: "0001-01-01T00:00:00",
      idSolicitacaoMaster: 0,
      Solicitacao: this.formulario.value.Solicitacao,
      Prioridade: this.formulario.value.Prioridade,
      NaturezaAtendimento: this.formulario.value.NaturezaAtendimento,
      idTipoServico: this.formulario.value.TipoServico.Id,
      Departamento: this.formulario.value.DepartamentoDestino.Nome,
      idDepartamento: this.formulario.value.DepartamentoDestino.Id,
      TipoServico: this.formulario.value.TipoServico.Servico,
      idEmpresa: this.formulario.value.Empresa.Id,
      Empresa: this.formulario.value.Empresa.RazaoSocial,
      NomeCliente: this.formulario.value.Cliente,
      DataIdentificacao: null,
      idTarefa: 0,
      CanalRetorno: this.formulario.value.Contato.Nome,
      PrazoInterno: null,
      Tarefa: null,
      idUsuarioAtribuido: 0,
      UsuarioAtribuido: "",
      Status: "",
      idFilialFocus: this.formulario.value.Filial.Id,
      FilialFocus: this.formulario.value.Filial.value,
      ListaHistorico: [],
      ListaAnexo: this.ListaArquivosUpload,
      ListaSolicitacaoDependente: [],
      DataCadastro: null,
      idUsuarioRelator: this.usuarioRelator.Id,
      UsuarioRelator: this.usuarioRelator.Nome,
      DepartamentoOrigem: null,
      RetornoCliente: this.formulario.value.Contato,
    });
    this.FormularioAtendimento.patchValue({
      Id: 0,
      StatusAtendimento: "",
      idCliente: this.formulario.value.idCliente,
      Cliente: this.formulario.value.Cliente,
      ContatoCliente: this.formulario.value.Contato,
      CanalSolicitacao: this.formulario.value.OrigemAtendimento,
      DataCadastro: null,
      UsuarioCadastro: this.usuarioRelator.Nome,
      DataAlteracao: null,
      UsuarioAlteracao: "",
      ListaSolicitacao: [this.FormularioSolicitacao.value],
    });
  }

  public gravar() {
    this.aparecerSpinner = true;
    this.montaObjGravar();
    this.StatusCheckbox();
    this.AtribuirCheckBox();

    this.atendimentoService
      .postSolicitacao([this.FormularioAtendimento.value])
      .toPromise()
      .then((response) => {
        this.abrirPaginaErro(response, "sistema");
        this.aparecerSpinner = false;
        if (
          response
            .map((r) => r.Descricao)
            .includes("Registro salvo com sucesso.")
        ) {
          this.dialogRef.close();
        }
      });
  }

  abrirPaginaErro(mensagem, tipo) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      mensagem: mensagem,
      tipo: tipo,
    };
    this.dialog.open(PaginaErroComponent, dialogConfig);
  }

  fecharPagina() {
    this.limparFormulario();
    this.dialogRef.close();
  }
}
