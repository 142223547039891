<div #mainWrapper [ngStyle]="{'background-color': (flagHomologacao) ? '#ffee00' : '#ffffff'}" style="display: flex; justify-content: space-between; width: 100%;">

    <div class="header-container">
        <div class="logo-container">
            <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
                <nb-icon icon="menu-2-outline"></nb-icon>
            </a>
            <div *ngIf="flagHomologacao" class="avisoHomologacao">
                Você está acessando o ambiente de homologação (teste)!
            </div>
        </div>

    </div>

    <div class="header-container">
        <nb-actions size="small">

            <nb-action class="control-item">
            </nb-action>


            <nb-action class="header-dimension">
              <button *ngIf="permissaoRegistroVisita.Permissao > 0" #tooltip="matTooltip" matTooltip="Registro de visita comercial" mat-icon-button color="basic" (click)="abrirRegistroDeVisitaComercial()">
                <mat-icon>add_location</mat-icon>
              </button>

              <button #tooltip="matTooltip" matTooltip="Controle de Solicitações" mat-icon-button color="basic" (click)="abrirControleSolicitacao()">
                <mat-icon>library_books</mat-icon>
              </button>

                <button #tooltip="matTooltip" matTooltip="Abrir nova solicitação" mat-icon-button color="basic" (click)="abrirDialogSolicitacao()">
          <mat-icon>library_add</mat-icon>
        </button>

                <button #tooltip="matTooltip" matTooltip="Buscar notificações" mat-icon-button color="basic" [disabled]="DisableBotaoRefresh" (click)="BuscarNotificacoes()" style="margin-right: 10px; cursor: pointer">
          <mat-icon>cached</mat-icon>
        </button>

                <button *ngIf="ListaNotificacaoMatch.length > 0" mat-icon-button (click)="abrirDialogMatch()" style="outline-width: 0;position: relative">
          <div id="wrapper" matBadge="{{ListaNotificacaoMatch.length}}" class="notificacaoBadge" matBadgeSize="small"
            matBadgePosition="after">
            <div id="pulsingheart"></div>
          </div>
        </button>
                <button *ngIf="ListaNotificacaoMatch.length == 0" mat-icon-button disabled>
          <mat-icon>favorite</mat-icon>
        </button>

                <button *ngIf="ListaNotificacao.length > 0" mat-icon-button>
          <mat-icon style="cursor: pointer" matBadge="{{ListaNotificacao.length}}" matBadgeColor="warn" color="basic"
            mat-button [matMenuTriggerFor]="menu">add_alert</mat-icon>
        </button>
                <button *ngIf="ListaNotificacao.length == 0" mat-icon-button disabled>
          <mat-icon style="cursor: pointer" matBadgeColor="warn" mat-button>add_alert</mat-icon>
        </button>
            </nb-action>

            <mat-menu xPosition="after" #menu="matMenu" style="width: 100px;">

                <button *ngIf="ListaNotificacao.length > 1" mat-menu-item>
          <section class="example-section">
            <mat-checkbox (change)="MarcarTodos()" (click)="$event.stopPropagation()" class="example-margin">
              <p style="font-size: 12px; margin-top: 10px;">MARCAR TODOS</p>
            </mat-checkbox>
          </section>
        </button>


                <button *ngFor="let Notificacao of ListaNotificacao; let i = index" (click)="abrirNotificacao(Notificacao)" mat-menu-item>
          <mat-checkbox style="display: none;" [(ngModel)]="Notificacao.Checked" class="example-margin">
          </mat-checkbox>
          <div style=" white-space: pre-wrap !important; max-height: 170px; word-wrap: break-all">
            <p style="font-size: 12px; margin-top: 5px;"> {{Notificacao.Descricao}} </p>
          </div>

        </button>

                <button *ngIf="ListaNotificacao.length > 0" (click)="MarcarComoLido()" style="width: 100%; height: 40px;" mat-raised-button color="primary">Marcar como visto</button>

            </mat-menu>
            <nb-action class="user-action" *ngIf="innerWidth > 900 else MatUser">
                <nb-user [nbContextMenu]="user ? userMenu : null" [onlyPicture]="userPictureOnly" [name]="user.user" [picture]="user?.picture" nbContextMenuTag="menu">

                </nb-user>
            </nb-action>
        </nb-actions>
    </div>
</div>

<ng-template #MatUser>
    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>person</mat-icon></button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="alterarSenha()">Alterar Senha</button>
        <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
</ng-template>