<div>
    <form [formGroup]="formulario">
        <input type="text" style="display: none;" matInput formControlName="idCliente">
        <div class="justify-content-around"></div>

        <div class="row">
            <div class="col-sm-7 col-12">

                <mat-form-field style="width: 100%;">
                    <mat-label>Cliente</mat-label>
                    <input type="text" readonly matInput formControlName="Cliente">
                    <button *ngIf="!formulario.get('idCliente').value" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="BuscarCliente($event)">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
              <path fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
            </svg>
          </button>

                    <button *ngIf="formulario.get('idCliente').value" (click)="excluirCliente()" mat-button matSuffix mat-icon-button aria-label="Clear">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>

                </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <mat-label>Empresa</mat-label>
                    <mat-select formControlName="Empresa">
                        <mat-option *ngFor="let empresa of SelectEmpresas" [value]="empresa">
                            {{empresa.RazaoSocial}} - {{ empresa.CNPJ }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <mat-label>Departamento de destino</mat-label>
                    <mat-select (selectionChange)="MudaSelects()" formControlName="DepartamentoDestino">
                        <mat-option *ngFor="let departamento of SelectDepartamentos" [value]="departamento">
                            {{departamento.Nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <mat-label>Tipo de serviço</mat-label>
                    <mat-select formControlName="TipoServico">
                        <mat-optgroup *ngFor="let linhaSelect of SelectServicos" [label]="linhaSelect.Titulo">
                            <mat-option *ngFor="let tipoServico of linhaSelect.TipoServico" [value]="tipoServico">
                                {{tipoServico.Servico}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <!--NATUREZA ATENDIMENTO-->
                <mat-form-field style="width: 100%">
                    <mat-label>Natureza</mat-label>
                    <mat-select formControlName="NaturezaAtendimento">
                        <mat-option *ngFor="let natureza of Naturezas" [value]="natureza">
                            {{natureza}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col-sm-5 col-12">

                <mat-form-field style="width: 100%;">
                    <mat-label>Prioridade</mat-label>
                    <mat-select formControlName="Prioridade">
                        <mat-option *ngFor="let prioridade of SelectPrioridades" [value]="prioridade">{{prioridade}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="!escreverContato">
                    <mat-form-field style="width: 100%;" class="caixa-texto">
                        <mat-label>Solicitante (Cliente)</mat-label>
                        <mat-select formControlName="Contato">
                            <mat-option *ngFor="let contato of SelectContato" [value]="contato.Nome">
                                {{contato.Nome}}
                            </mat-option>
                        </mat-select>

                        <button class="ml-2" (click)="inserirContato()" mat-button matSuffix mat-icon-button aria-label="Clear">
              <svg width="1.5em" height="1.5em" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                <path
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
              </svg>
            </button>
                    </mat-form-field>
                </div>

                <div *ngIf="escreverContato">
                    <mat-form-field style="width: 100%;" class="caixa-texto">
                        <mat-label>Informe o Solicitante (Cliente)</mat-label>
                        <input formControlName="Contato" maxlength="50" matInput type="text">

                        <button *ngIf="escreverContato" (click)="inserirContato()" mat-button matSuffix mat-icon-button aria-label="Clear">
              <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
                    </mat-form-field>
                </div>

                <mat-form-field style="width: 100%;">
                  <mat-label>Origem da Solicitação</mat-label>
                  <mat-select formControlName="OrigemAtendimento">
                      <mat-option *ngFor="let origem of OrigemAtendimento" [value]="origem">{{origem}}</mat-option>
                  </mat-select>
                  <mat-hint  align="end" *ngIf="formulario.value.OrigemAtendimento !== 'Tratativa Interna Focus' && formulario.value.OrigemAtendimento !== ''">
                     <span class = "color-text"> Á origem da solicitação selecionada será acompanhada pelo cliente no CRM </span>
                  </mat-hint>
              </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <mat-label>Filiais</mat-label>
                    <mat-select formControlName="Filial" #filial>
                        <mat-option *ngFor="let filial of SelectFiliais" [value]="filial">{{filial.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-checkbox [disabled]="formulario.value.StatusAtendido || CheckboxDisable" [checked]="formulario.value.StatusAtendido" formControlName="Atribuir" style="width: 110%;">Atribuir a solicitação ao meu usuário</mat-checkbox>
                <br>
                <mat-checkbox [disabled]="CheckboxDisable" formControlName="StatusAtendido" style="width: 100%;" (click)="setCheckbox()">Salvar com status de atendido.
                </mat-checkbox>

            </div>



        </div>



        <mat-label class="boxtextlabel">Descrição da solicitação do cliente</mat-label>
        <textarea [readonly]="formulario.get('Id').value != 0 ? true : false" style="height: 120px" formControlName="Solicitacao" class="form-control" aria-label="With textarea"><pre></pre></textarea>

    </form>

    <br>

    <div class="d-flex justify-content-end mb-2">
        <a style="cursor: pointer; font-size: 13px; font-weight: bolder !important;" (click)="AbrirCaixaArquivos()">
            <svg width="1.3em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
        <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      </svg> Adicionar Arquivo
            <br>
            <p class="boxtextlabel"> .pdf, .xlsx, .xls, .txt, .csv, .docx </p>
        </a>
    </div>

    <input type="file" id="CaixaArquivos" accept=".pdf, .xlsx, .xls, .txt, .csv, .docx" lang="pt" multiple (change)="ArquivosSelecionados($event.target.files)" hidden>

    <table mat-table style="max-height: 200px !important; width: 100% !important;" [dataSource]="ListaArquivosUpload" class="mat-elevation-z0" multiTemplateDataRows>

        <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef style="font-size: 13px"> Nome do Arquivo
            </th>
            <td mat-cell *matCellDef="let element;let i = dataIndex">
                <div class="d-flex justify-content-between">
                    {{element.NomeArquivo}}
                    <button mat-button color="warn" (click)="ExcluirArquivo(i)">Excluir</button>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="d-flex justify-content-end"></div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colunaNomeArquivo"></tr>

        <tr mat-row *matRowDef="let element; columns: colunaNomeArquivo;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>


    <!-- <div style="overflow-x: auto;"> -->

    <div [ngClass]="larguraPagina > 500 ? 'd-flex justify-content-end mt-4' : ''">
        <div *ngIf="!aparecerSpinner; else spinner">
            <button [ngClass]="larguraPagina > 500 ? 'btn btn-primary mx-2' : 'btn btn-primary btn-block'" (click)="gravar()" [disabled]="formulario.status == 'INVALID' ? true : false">
        Gravar
      </button>
        </div>

        <button [ngClass]="larguraPagina > 500 ? 'btn btn-danger' : 'btn btn-danger btn-block'" (click)="fecharPagina()">Voltar</button>

    </div>
</div>


<ng-template #spinner>
    <button [ngClass]="larguraPagina > 500 ? 'btn btn-primary mx-2' : 'btn btn-primary btn-block'" (click)="gravar()" [disabled]="aparecerSpinner">
    <mat-spinner disabled [diameter]="24" style="margin-left: 23px" class="mat-spinner-color"></mat-spinner>
  </button>
</ng-template>