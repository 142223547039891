import { UsuarioService } from './../../../../services/usuario.service';
import { SolicitacaoComponent } from './../../../pages/componetes-comum/solicitacao/solicitacao.component';
import { Component, OnDestroy, OnInit, Inject, AfterViewInit, ViewChild, AfterViewChecked, Renderer2 } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login.service';
import { TokenService } from '../../../../services/token.service';
import { CookieService } from 'ngx-cookie-service';
import { NotificacaoService } from '../../../../services/notificacao.service';
import { environment } from '../../../../environments/environment';
import { Ambiente } from '../../../ambiente/ambiente';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificacaoMatchComponent } from '../../../pages/componetes-comum/notificacao-match/notificacao-match.component';
import { ArquivoService } from '../../../../services/arquivo.service';
import { PaginaErroComponent } from '../../../pages/componetes-comum/pagina-erro/pagina-erro.component';
import { GeradorPdfComponent } from '../../../pages/componetes-comum/gerador-pdf/gerador-pdf.component';
import { PermissaoComponent } from '../../../pages/principal/principal.component';

export enum TipoNotificacao {
  ATAREUNIAO, SOLICITACAO
}

export interface Notificacao {
  Checked: Boolean,
  Data: String,
  Descricao: String,
  Id: Number,
  Lido: Number,
  idAlvo: Number,
  idUsuario: Number,
  Tipo: Number
}

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  public idIntervaloNotificacao;

  public checked = false;
  public usuario;

  public flagHomologacao = environment.ambiente == 'CRMFocus_HOM' ? true : false


  public permissaoRegistroVisita: PermissaoComponent = {
    IdUsuario: 0,
    cdFuncao: 0,
    dsFuncao: '',
    Permissao: 0
  }

  @ViewChild('mainWrapper') mainWrapper: HTMLElement;

  public innerWidth: any;

  public ListaNotificacao = []
  public ListaNotificacaoMatch = []
  public DisableBotaoRefresh = false;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userMenu;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  gerandoRelatorio: boolean;
  ParametrosNotificacao: any[];



  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private sideBarService: NbSidebarService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private loginService: LoginService,
    private nbMenuService: NbMenuService,
    private tokenService: TokenService,
    private cookie: CookieService,
    private notificacaoService: NotificacaoService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private arquivoService: ArquivoService,
  ) {

    this.innerWidth = window.innerWidth

    this.usuario = this.loginService.getUsuarioDadosCompletos();
    this.notificacaoService.buscaNotificacoes()

    this.loginService.getPermissoesUsuario()
    .then(response => {
      response.forEach(linha => {
        if(linha.cdFuncao == 1015){
          this.permissaoRegistroVisita = linha
        }
      })
    })

    this.idIntervaloNotificacao = setInterval(() => {
      this.notificacaoService.buscaNotificacoes();
    }, 60000)

    this.menuService.onItemClick()
    .subscribe(() => {
      this.sideBarService.compact('menu-sidebar')
    })

  }

  ngOnInit() {
    this.notificacaoService.listaNovasNotificacoesObservable.subscribe(NovasNotificacoes => {

      this.ListaNotificacao = []
      NovasNotificacoes.forEach(item => {
        item['Checked'] = false

        if (item.Tipo != 1) this.ListaNotificacao.push(item)

        if (item.Tipo == 1) this.ListaNotificacaoMatch.push(item)

      })
    })

    if (Notification.permission !== "granted") {
      Notification.requestPermission()
    }

    this.notificacaoService.novaNotificacaoObservable.subscribe(notificacao => {

      notificacao['Checked'] = false
      if (notificacao.Tipo != 1) this.ListaNotificacao.push(notificacao)
      if (notificacao.Tipo == 1) this.ListaNotificacaoMatch.push(notificacao)

      if (Notification.permission !== "granted") {
        Notification.requestPermission()
      } else {
        this.showNotification(notificacao)
      }

    })

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(response => {
        if (response == 'Logout') {
          this.logout()
        }
        if (response == 'Alterar senha') {
          this.alterarSenha()
        }
        if (response == 'Alterar Foto') {
          this.alterarFoto()
        }
      });

    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => {
        if(this.usuario.Foto == 1){
        this.user = {
          user: this.tokenService.getNomeUsuario(),
          picture: environment.Site+ '/imagens/' + this.usuario.PathFoto
        }}
        else {
          this.user = {
            user: this.tokenService.getNomeUsuario(),
            picture: environment.Site + '/Imagens/icone.png'
          }
        }
        this.userMenu = [{ title: 'Alterar senha' },{ title: 'Alterar Foto' } ,{ title: 'Logout' }]
      });
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  abrirControleSolicitacao(){
    this.router.navigate(['/pages/operacoes/consultasolicitacoes'])
  }

  abrirRegistroDeVisitaComercial(){
    this.router.navigate(['pages/cadastros/registro-visita-comercial'])
  }

  logout() {
    this.loginService.fazerLogout()
    this.router.navigate(['/login'])
    this.cookie.deleteAll('/')
    this.cookie.delete("Login", '/')
    this.cookie.delete("Senha", '/')
    this.cookie.delete("Ambiente", '/')

    this.notificacaoService.ListaNotificacao = []
    clearInterval(this.idIntervaloNotificacao)
  }

  alterarSenha() {
    this.router.navigate(['/pages/mudarsenha'])
  }

  alterarFoto(){
    this.router.navigate(['/pages/alterarfoto'])
  }


  public MarcarDesmarcar(Notificacao, i) {
    this.ListaNotificacao[i].Checked = !Notificacao.Checked
  }

  public MarcarTodos() {

    this.ListaNotificacao.forEach(
      notificacao => {

        if (this.checked) {
          notificacao.Checked = false
        } else {
          notificacao.Checked = true
        }
      }
    )

    this.checked = !this.checked
  }

  public MarcarComoLido() {

    let ListaEnviar = this.ListaNotificacao.filter(Notificacao => Notificacao.Checked)
    ListaEnviar.forEach(item => {
      item.Lido = 1
    })

    this.notificacaoService.post(ListaEnviar)
    this.ListaNotificacao = this.ListaNotificacao.filter(Notificacao => !Notificacao.Checked)
  }

  public BuscarNotificacoes() {

    this.notificacaoService.buscaNotificacoes();
    this.DisableBotaoRefresh = true;

    setTimeout(() => {
      this.DisableBotaoRefresh = false;
    }, 60000)

  }

  public showNotification(Notificacao) {
    const notification = new Notification("Notificação do CRM", {
      body: Notificacao.Descricao
    })
  }
  public abrirDialogMatch() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = this.ListaNotificacaoMatch[this.ListaNotificacaoMatch.length - 1];
    dialogConfig.panelClass = 'custom-dialog-container';

    this.dialog.open(NotificacaoMatchComponent, dialogConfig).afterClosed().toPromise()
      .then(response => {
        this.ListaNotificacaoMatch.pop()
      }

      )
  }

  public abrirDialogSolicitacao() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'solicitacao-Dialog'

    dialogConfig.data = {
      usuarioLogado: this.usuario
    }
    this.dialog.open(SolicitacaoComponent, dialogConfig)
  }
  // Bloco que manipula as notificações(abre as solicitações e baixa as atas!)
  public abrirNotificacao(element: Notificacao): void {

      if(element.Tipo == 3){
        element.Lido = 1
        this.notificacaoService.post([element])
        this.ListaNotificacao = this.ListaNotificacao.filter(n => n.Id != element.Id)
      }

      if (element.Tipo == 2) {
        this.router.navigateByUrl('/pages/operacoes/consultasolicitacoes', { state: { idSolicitacao: element.idAlvo } })
        element.Lido = 1
        this.notificacaoService.post([element])
        this.ListaNotificacao = this.ListaNotificacao.filter(n => n.Id != element.Id)
      }

      if (element.Tipo == 0) {
        this.GerarRelatorio(element)
      }
  }

  async GerarRelatorio(notificacao: Notificacao): Promise<void> {

    this.gerandoRelatorio = true;
    this.GerarParametros(notificacao.idAlvo)
    await this.arquivoService.gerarRelatorio(this.ParametrosNotificacao, "ata de reunião").toPromise()
      .then(response => {
        this.GeraDocumento(response)
        notificacao.Lido = 1
        this.notificacaoService.post([notificacao])
        this.ListaNotificacao = this.ListaNotificacao.filter(n => n.Id != notificacao.Id)
       })
      .catch(error => this.PaginaErro(error, "erro"))

    this.gerandoRelatorio = false;
  }
  private GerarParametros(notificacao) {
    this.ParametrosNotificacao = []
    this.ParametrosNotificacao.push(
      {
        Nome: 'Id',
        Valor: String(notificacao)
      })
  }

  public PaginaErro(mensagem, tipo) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      mensagem: mensagem,
      tipo: tipo
    }

    this.dialog.open(PaginaErroComponent, dialogConfig)
  }

  public GeraDocumento(documento) {
    const dialogConfig = new MatDialogConfig();
    const blob = new Blob([documento], { type: "application/pdf" })
    const fileURL = URL.createObjectURL(blob);
    // window.open(fileURL, '_blank');

    dialogConfig.data = {
      ArquivoPDF: fileURL,
      NomeArquivo: "Relatório de Ata Reunião"
    }
    dialogConfig.panelClass = 'customDialogPdf'

    this.dialog.open(GeradorPdfComponent, dialogConfig)
  }

}
